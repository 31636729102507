// Write your custom css/scss here
.pagination {
    margin-bottom: 0;
}

button.btn-search {
    padding: 0.2rem 0.8rem;
}

button.tags {
    padding: 0.3rem 1rem;
}
button.tags::after {
    margin-left: 0 !important;
}

.related_product {
    margin-left: 10px;
    cursor: pointer;
}

.related_product-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

// Order states
.state {
    font-weight: 600;
    border-radius: 5px;
    padding: 0 10px;

    &-en_proceso, &-cliente {
        background-color: dodgerblue;
        color: white;
    }

    &-impreso {
        background-color: darkviolet;
        color: white;
    }

    &-en_mano {
        background-color: grey;
        color: white;
    }

    &-preparado, &-empaquetado, &-admin {
        background-color: #ffc107;
        color: black;
    }

    &-enviado, &-disponible, &-entregado {
        background-color: #10b759;
        color: white;
    }

    &-indicar_casilla {
        background-color: yellow;
        color: black;
    }

    &-cancelado {
        background-color: black;
        color: white;
    }

    &-retirar {
        background-color: #64E8E4;
        color: black;
    }

    &-encuadernar {
        background-color: red;
        color: white;
    }

    //Row backgrounds
    &-bg-proceso {
        background-color: white !important;

        input[type="checkbox"] {
            accent-color: dodgerblue;
        }
    }

    &-bg-cancelado, &-bg-mano {
        background-color: #eeeeee !important;
        border-color: #eeeeee !important;

        input[type="checkbox"] {
            accent-color: black;
        }
    }

    &-bg-impreso {
        background-color: #e0cfe8 !important;
        border-color: #e0cfe8 !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: darkviolet;
        }
    }

    &-bg-retirar {
        background-color: #d9fefc !important;
        border-color: #ebd3d3 !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: #98e5e1;
        }
    }

    &-bg-casilla {
        background-color: #ffffe5 !important;
        border-color: #e5e5ce !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: yellow;
        }
    }

    &-bg-preparado, &-bg-empaquetado {
        background-color: #fff3cd !important;
        border-color: #ffeeba !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: #ffc107;
        }
    }

    &-bg-enviado , &-bg-disponible , &-bg-entregado {
        background-color: #cff1de !important;
        border-color: #bcebd1 !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: #10b759;
        }
    }
}

//Order comment button
.comment {

    .dropdown-menu {
        background-color: #1026FB;
        color: white;
        font-weight: bold;
        border: none;
    }

    button {
        background-color: transparent;
        border: none;

        svg {
            stroke: #0e4cfd;
        }
    }
}

//Order shipping info
.row-shipping {

    .dropdown-menu {
        background-color: #1026FB;
        color: white;
        font-weight: bold;
        border: none;
    }

    button {
        background-color: transparent;
        border: none;

        svg {
            // fill: #0e4cfd;
            stroke: #0e4cfd;
        }
    }

}

//Member administrator
.bg-member-active {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

//Permission center tables
.permissions-table tr > th:first-child, .permissions-table tr > td:first-child {
    position: sticky;
    min-width: 290px;
    left: 0;
    z-index: 1;
}
.permissions-table tr > th:nth-child(2), .permissions-table tr > td:nth-child(2) {
    position: sticky;
    left: 290px;
    background: $white;
    z-index: 0;
}
.permissions-table tr:nth-child(odd) td, .permissions-table tr:nth-child(even) td {
    background: $white;
}

//Color Badges
.badge.badge-violet {
    background-color: darkviolet;
    color: white;
}

//Session messages
.session-fixed {
    top: 60px !important;
    width: calc(100% - 70px);
    z-index: 1030;
    display: block;
    margin-left: 70px;
    transition: width 0.1s ease;
}
.session-fixed-folded {
    width: calc(100% - 270px) !important;
    margin-left: 270px;
}
